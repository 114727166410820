import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Bind } from "../../decorators/bind.decorator";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { Rules } from "./s25.rule.const";
import { PreferenceService } from "../../services/preference.service";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";
import DowChar = EventSummary.DowChar;

@TypeManagerDecorator("s25-ng-rule-condition")
@Component({
    selector: "s25-ng-rule-condition",
    template: `
        @if (isInit) {
            <div class="dropdown-container c-objectDetails c-objectDetails--borderedSection">
                <div class="c-sectionHead d-flex">
                    <p class="header-label">
                        <span class="bold">{{ type.label }} </span>
                        <span>{{ condition.sourceItem?.itemName }} </span>
                    </p>
                </div>
                <s25-simple-collapse [titleText]="'Condition'">
                    <div class="condition">
                        <label>
                            <span class="label">Source: </span>
                            <select [(ngModel)]="source" (ngModelChange)="onSourceChange()" class="cn-form__control">
                                @for (source of sources; track source) {
                                    <option [ngValue]="source">
                                        {{ source.label }}
                                    </option>
                                }
                            </select>
                        </label>
                        @if (source.isGroup) {
                            <label>
                                <span class="label"></span>
                                <select [(ngModel)]="type" (ngModelChange)="onTypeChange()" class="cn-form__control">
                                    @for (t of Rules.groups[source.id]; track t) {
                                        <option [ngValue]="t">
                                            {{ t.label }}
                                        </option>
                                    }
                                </select>
                            </label>
                        }
                        @if (type.sourceItems) {
                            <label>
                                <span class="label">{{ type.sourceItems.label }}: </span>
                                <s25-generic-dropdown
                                    [items]="type.sourceItems.options"
                                    [(chosen)]="condition.sourceItem"
                                ></s25-generic-dropdown>
                            </label>
                        }
                        @if (type === Rules.type.CustomAttribute) {
                            <label>
                                <span class="label">Attribute: </span>
                                <s25-generic-dropdown
                                    [items]="customAttributes"
                                    [(chosen)]="condition.sourceItem"
                                    (chosenChange)="onAttributeChange()"
                                    [searchEnabled]="true"
                                    [placeholder]="'Select Custom Attribute'"
                                ></s25-generic-dropdown>
                            </label>
                        }
                        @if (valueType.type !== "attribute" && valueType.type !== "matchQuestion") {
                            <label>
                                <span class="label">Operator: </span>
                                <select [(ngModel)]="condition.operator" class="cn-form__control">
                                    @for (operator of valueType.operators; track operator) {
                                        <option [ngValue]="operator">
                                            {{ operator }}
                                        </option>
                                    }
                                </select>
                            </label>
                        }
                        @if (valueType.type === "matchQuestion") {
                            <s25-ng-dropdown-search-criteria
                                [(chosen)]="condition.sourceItem"
                                [onLoad]="onSourceSelectorLoaded"
                                [type]="valueType.criterion"
                                [customFilterValue]="conditionFilterMap?.[valueType.criterion] ?? valueType.filter"
                            ></s25-ng-dropdown-search-criteria>
                            @if (condition.sourceItem?.itemTypeId) {
                                <label class="c-margin-top--single">
                                    <span class="c-margin-right--half">In: </span>
                                    <select [(ngModel)]="condition.values" multiple>
                                        @for (child of condition.sourceItem.children; track child) {
                                            <option [value]="child.itemId">
                                                {{ child.itemName }}
                                            </option>
                                        }
                                    </select>
                                </label>
                            }
                        }
                        @if (valueType?.type === "multiselect" && !Rules.valuelessOperators.has(condition.operator)) {
                            <s25-ng-multiselect-search-criteria
                                *s25-ng-trigger-rerender="multiselectModel"
                                [type]="valueType.criterion"
                                [customFilterValue]="conditionFilterMap?.[valueType.criterion] ?? valueType.filter"
                                [modelBean]="multiselectModel"
                                [selectedItems]="condition.values"
                                [popoverOnBody]="true"
                                [popoverPlacement]="'right'"
                            ></s25-ng-multiselect-search-criteria>
                        }
                        @if (
                            valueType.type !== "multiselect" &&
                            valueType.type !== "matchQuestion" &&
                            valueType.type !== "attribute" &&
                            !Rules.valuelessOperators.has(condition.operator)
                        ) {
                            <div>
                                @for (value of condition.values; track trackByIndex(i); let i = $index) {
                                    <div class="valueWrapper">
                                        <label>
                                            <span class="label">Value: </span>
                                        </label>
                                        <div class="value">
                                            @switch (valueType.type) {
                                                @case ("boolean") {
                                                    <s25-editable-boolean
                                                        [model]="{
                                                            data: $any(condition.values[i]),
                                                            falseLabel: 'No',
                                                            trueLabel: 'Yes',
                                                        }"
                                                        (modelValueChange)="condition.values[i] = $event"
                                                    ></s25-editable-boolean>
                                                }
                                                @case ("text") {
                                                    <s25-ng-editable-text
                                                        [(val)]="condition.values[i]"
                                                        [max]="valueType.maxLength"
                                                        [alwaysEditing]="true"
                                                    ></s25-ng-editable-text>
                                                }
                                                @case ("discrete") {
                                                    @if (condition.operator !== "contains") {
                                                        <s25-generic-dropdown
                                                            [items]="discreteOptions[condition.sourceItem.itemId]"
                                                            [(chosen)]="condition.values[i]"
                                                            [placeholder]="'Select a value'"
                                                        ></s25-generic-dropdown>
                                                    }
                                                    @if (condition.operator === "contains") {
                                                        <s25-ng-editable-text
                                                            [val]="$any(condition.values[i])?.itemId"
                                                            (valChange)="condition.values[i] = $any({ itemId: $event })"
                                                            [alwaysEditing]="true"
                                                        ></s25-ng-editable-text>
                                                    }
                                                }
                                                @case ("number") {
                                                    <s25-ng-editable-number
                                                        [(val)]="condition.values[i]"
                                                        [type]="valueType.flavor"
                                                        [alwaysEditing]="true"
                                                    ></s25-ng-editable-number>
                                                }
                                                @case ("textarea") {
                                                    <s25-ng-editable-textarea
                                                        [(val)]="condition.values[i]"
                                                        [max]="valueType.maxLength"
                                                        [alwaysEditing]="true"
                                                    ></s25-ng-editable-textarea>
                                                }
                                                @case ("date") {
                                                    <s25-ng-editable-date
                                                        [(val)]="condition.values[i]"
                                                    ></s25-ng-editable-date>
                                                }
                                                @case ("datetime") {
                                                    <s25-ng-editable-date-time
                                                        [(val)]="condition.values[i]"
                                                    ></s25-ng-editable-date-time>
                                                }
                                                @case ("time") {
                                                    <s25-timepicker
                                                        [(modelValue)]="condition.values[i]"
                                                    ></s25-timepicker>
                                                }
                                                @case ("occurrenceDate") {
                                                    <div class="between">
                                                        <s25-ng-editable-date-time
                                                            [val]="condition.values[i]?.[0]"
                                                            [allowEmpty]="false"
                                                            (valChange)="
                                                                condition.values[i] = [$event, condition.values[i]?.[1]]
                                                            "
                                                        ></s25-ng-editable-date-time>
                                                        @if (condition.operator === "between") {
                                                            <p>and</p>
                                                            <s25-ng-editable-date-time
                                                                [val]="condition.values[i]?.[1]"
                                                                [allowEmpty]="false"
                                                                (valChange)="
                                                                    condition.values[i] = [
                                                                        condition.values[i]?.[0],
                                                                        $event,
                                                                    ]
                                                                "
                                                            ></s25-ng-editable-date-time>
                                                        }
                                                    </div>
                                                }
                                                @case ("occurrenceTime") {
                                                    <div class="between">
                                                        <s25-timepicker
                                                            [modelValue]="condition.values[i]?.[0]"
                                                            (modelValueChange)="
                                                                condition.values[i] = [$event, condition.values[i]?.[1]]
                                                            "
                                                        ></s25-timepicker>
                                                        @if (condition.operator === "between") {
                                                            <p>and</p>
                                                            <s25-timepicker
                                                                [modelValue]="condition.values[i]?.[1]"
                                                                (modelValueChange)="
                                                                    condition.values[i] = [
                                                                        condition.values[i]?.[0],
                                                                        $event,
                                                                    ]
                                                                "
                                                            ></s25-timepicker>
                                                        }
                                                    </div>
                                                }
                                                @case ("occurrenceDow") {
                                                    <div>
                                                        <div class="checkboxes">
                                                            @for (dow of dows; track dow) {
                                                                <s25-ng-checkbox
                                                                    [(modelValue)]="condition.values[i][dow.value]"
                                                                >
                                                                    {{ dow.label }}
                                                                </s25-ng-checkbox>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                @case ("search") {
                                                    <div>
                                                        <s25-ng-search-dropdown
                                                            [itemTypeId]="valueType.itemType"
                                                            [allowNonQueryId]="false"
                                                            [initialSearch]="
                                                                condition.values[i] && {
                                                                    itemTypeId: valueType.itemType,
                                                                    property: 'itemId',
                                                                    value: $any(condition.values[i]).itemId,
                                                                }
                                                            "
                                                            (chosenChange)="condition.values[i] = $any($event)"
                                                            [onlyPublic]="valueType.public"
                                                        ></s25-ng-search-dropdown>
                                                    </div>
                                                }
                                                @case ("checkbox") {
                                                    <div>
                                                        <s25-ng-checkbox
                                                            [modelValue]="!!condition.values[i]"
                                                            (modelValueChange)="condition.values[i] = $event"
                                                        ></s25-ng-checkbox>
                                                    </div>
                                                }
                                                @case ("toggle") {
                                                    <div>
                                                        <s25-toggle-button
                                                            [modelValue]="!!condition.values[i]"
                                                            (modelValueChange)="condition.values[i] = $event"
                                                            [trueLabel]="valueType.true"
                                                            [falseLabel]="valueType.false"
                                                        />
                                                    </div>
                                                }
                                                @case ("dropdown") {
                                                    <div>
                                                        <s25-generic-dropdown
                                                            [items]="type.valueType.options"
                                                            [chosen]="{ itemId: $any(condition.values[i]) }"
                                                            (chosenChange)="condition.values[i] = $event.itemId"
                                                        />
                                                    </div>
                                                }
                                                @case ("relativeDate") {
                                                    <div class="relativeDate">
                                                        <div>
                                                            <s25-ng-editable-number
                                                                [val]="$any(condition.values[i])?.days || 0"
                                                                (valChange)="
                                                                    condition.values[i] = {
                                                                        days: $event,
                                                                        type: $any(condition.values[i])?.type,
                                                                    }
                                                                "
                                                                [type]="'integer'"
                                                                [alwaysEditing]="true"
                                                                [min]="-999999"
                                                            ></s25-ng-editable-number>
                                                            <select
                                                                class="cn-form__control"
                                                                [ngModel]="
                                                                    $any(condition.values[i])?.type || 'eventStart'
                                                                "
                                                                (ngModelChange)="
                                                                    condition.values[i] = {
                                                                        days: $any(condition.values[i])?.days,
                                                                        type: $event,
                                                                    }
                                                                "
                                                            >
                                                                <option [ngValue]="'eventStart'">
                                                                    Days from event start
                                                                </option>
                                                                <option [ngValue]="'eventEnd'">
                                                                    Days from event end
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <p class="hint">Number of days may be negative</p>
                                                    </div>
                                                }
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div class="c-margin-top--single buttons">
                            <button
                                (click)="selfDestruct.emit()"
                                class="aw-button aw-button--danger--outline rule-remove"
                            >
                                Remove
                            </button>
                            @if (
                                (condition.operator === "in" || condition.operator === "not in") &&
                                type === Rules.type.CustomAttribute &&
                                valueType.type !== "multiselect"
                            ) {
                                <button (click)="addValue()" class="aw-button aw-button--outline">
                                    Add Another Value
                                </button>
                            }
                        </div>
                    </div>
                </s25-simple-collapse>
            </div>
        }
    `,
    styles: `
        ::ng-deep #s25.nm-party--on s25-ng-rule-condition .c-objectDetails {
            border: 1px solid #28272c;
        }

        ::ng-deep #s25.nm-party--on s25-ng-rule-condition .c-sectionHead {
            border-top-right-radius: 0 !important;
        }

        .dropdown-container {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: 0 !important;
            margin: 1em 0;
        }

        .condition {
            max-width: 500px;
            padding: 1em;
        }

        label {
            display: flex;
            margin-bottom: 1em;
        }

        label > .label {
            width: 100px;
            margin: auto;
        }

        label > .label + * {
            flex-grow: 1;
        }

        .header-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.5em 3em 0.5em 1em;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
        }

        .between p {
            padding: 0.5em 0;
        }

        .valueWrapper {
            display: flex;
        }

        .valueWrapper label {
            margin: 0;
        }

        ::ng-deep s25-ng-rule-condition .valueWrapper s25-editable-boolean label {
            margin: 0 -1em 0 1em;
        }

        .valueWrapper .value {
            flex: 1;
        }

        ::ng-deep s25-ng-rule-condition s25-ng-editable-number input,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-text input,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-date s25-datepicker,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-date s25-datepicker input,
        ::ng-deep s25-ng-rule-condition s25-ng-editable-textarea textarea {
            width: 100% !important;
        }

        ::ng-deep s25-ng-rule-condition s25-ng-editable-date-time > div {
            display: grid;
            gap: 0.5rem;
        }

        ::ng-deep s25-ng-rule-condition s25-ng-editable-date s25-datepicker input {
            padding: 0 8px !important;
        }

        ::ng-deep s25-ng-rule-condition s25-generic-dropdown .select2-choice {
            padding-left: 3px !important;
        }

        .checkboxes {
            gap: 0.5rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(100%, 4em), 1fr));
        }

        .relativeDate > div {
            display: flex;
            gap: 0.5em;
            flex-wrap: wrap;
        }

        .relativeDate select {
            flex-grow: 1;
        }

        .relativeDate .hint {
            font-size: 0.9em;
            color: #777;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RuleConditionComponent implements OnInit {
    @Input() category: Rules.Category = "form";
    @Input() condition: Rules.Condition;
    @Input() discreteOptions: Record<number, S25ItemI[]> = {};
    @Input() customAttributes: Rules.SourceItem[];
    @Input() order: number; // Used to retrieve sort order when saving
    @Input() conditionFilterMap?: Rules.ConditionFilterMap;

    @Output() selfDestruct = new EventEmitter<void>();

    // Template aliases
    Rules = Rules;

    isInit = false;
    sources: Rules.Source[];
    source: Rules.Source;
    type: Rules.Type;
    valueType: Rules.ValueType;
    multiselectModel: MultiselectModelI;
    dows = [
        { value: "M" as const, label: "Mon" as const },
        { value: "T" as const, label: "Tue" as const },
        { value: "W" as const, label: "Wed" as const },
        { value: "R" as const, label: "Thu" as const },
        { value: "F" as const, label: "Fri" as const },
        { value: "S" as const, label: "Sat" as const },
        { value: "U" as const, label: "Sun" as const },
    ];

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.sources = Rules.sources[this.category];
        this.condition.type ??= this.sources[0].id as Rules.TypeId;
        const firstDow = (await PreferenceService.getPreferences(["CalendarView"])).CalendarView.value || 7;
        S25Util.array.rotate(this.dows, 8 - firstDow);

        this.source = Rules.typeIdToSource[this.condition.type];
        this.type = Rules.typeIdToType[this.condition.type];
        this.setValueType();
        if (!this.condition.operator) this.setDefaultOperator();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    onSourceChange() {
        if ("isGroup" in this.source) {
            this.type = Rules.groups[this.source.id][0];
        } else {
            this.type = Rules.typeIdToType[this.source.id];
        }
        this.onTypeChange();
    }

    onSourceSelectorLoaded = () => {
        this.changeDetector.detectChanges();
    };

    onTypeChange() {
        this.condition.type = this.type.id;
        this.setDefaultSourceItem();
        this.setValueType();
        this.setDefaultOperator();
        this.resetValues();
        this.changeDetector.detectChanges();
    }

    onAttributeChange() {
        this.setValueType();
        this.setDefaultOperator();
        this.resetValues();
        this.changeDetector.detectChanges();
    }

    @Bind
    onItemChooserChange() {
        const selected = this.multiselectModel.selectedItems;
        this.condition.values = selected.map(({ itemId, itemName }: Rules.ItemValue) => ({ itemId, itemName }));
        this.changeDetector.detectChanges();
    }

    setValueType() {
        // If source is custom attribute, select valueType based on the selected attribute
        if (this.type === Rules.type.CustomAttribute && this.condition.sourceItem) {
            if (
                this.condition.sourceItem.attributeType === Rules.attributeType.Text &&
                this.discreteOptions[this.condition.sourceItem.itemId]
            ) {
                this.valueType = Rules.valueType.Discrete;
                // Discrete values need to be mapped to a dropdown format
                this.condition.values = this.condition.values.map((str: string) => ({ itemId: str, itemName: str }));
            } else {
                this.valueType =
                    Rules.attributeValueType[
                        this.condition.sourceItem.attributeType as keyof typeof Rules.attributeValueType
                    ];
            }
        } else {
            this.valueType = this.type.valueType;
        }

        if (this.valueType.type === "multiselect") {
            this.multiselectModel = {
                showResult: true,
                onChange: this.onItemChooserChange,
                title: this.valueType.label,
            };
        }
    }

    resetValues() {
        if (this.valueType.type === "multiselect") this.condition.values = [];
        else if (this.valueType.type === "occurrenceDow") this.condition.values = [{} as Record<DowChar, boolean>];
        else if (this.valueType.type === "relativeDate") this.condition.values = [{ days: 0, type: "eventStart" }];
        else this.condition.values = [null];
        this.changeDetector.detectChanges();
    }

    setDefaultSourceItem() {
        if ("sourceItems" in this.type && this.type.sourceItems.options.length) {
            this.condition.sourceItem = this.type.sourceItems.options[0];
        } else {
            this.condition.sourceItem = null;
        }
        this.changeDetector.detectChanges();
    }

    setDefaultOperator() {
        this.condition.operator = this.valueType.operators[0];
    }

    addValue() {
        this.condition.values.push(null);
        this.changeDetector.detectChanges();
    }

    validate() {
        if (Rules.valuelessOperators.has(this.condition.operator)) return;

        if ("transform" in this.valueType) {
            this.condition.values = this.valueType.transform(this.condition.values);
        }

        if ("validate" in this.valueType) {
            const ok = this.valueType.validate(this.condition.values);
            if (!ok) return "Please fill out conditions for all rules";
            return;
        }

        if (this.condition.type === Rules.type.CustomAttribute.id && !this.condition.sourceItem)
            return "Please pick a custom attribute";
        else if (!this.condition.values.filter((val) => val !== null).length)
            return "Please fill out conditions for all rules";
    }

    trackByIndex(index: number) {
        return index;
    }
}
